import React from "react"
import { getLocalizedURL } from "@src/helper"
// Style Imports
// import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"
import UnsubscribeForm from "@components/form/Form.Unsubscribe.jsx"

const unsubscribe = () => (
    <Layout addedClass="page--unsubscribe">
        <Seo 
        title="Cancelar suscripción | LUPKYNIS&reg; (voclosporina)" 
        description="Lamentamos que se vaya. Cancele su suscripción aquí si ya no desea recibir actualizaciones e información sobre LUPKYNIS. Consulte la Información de prescripción y seguridad completa y el RECUADRO DE ADVERTENCIA." 
        canonicalURL={"https://www.lupkynis.com" + getLocalizedURL('unsubscribe','es')} />
        <Hero addedClass="bg-light-green hero--string">
            <div className="col-wrapper">
                <div className="container">
                    <div className="hero__content">
                        <h1 className="heading heading--accent">¿Se va?</h1>
                        <h2>Lamentamos que se vaya</h2>
                        <p>Simplemente ingrese su dirección de correo electrónico a continuación para cancelar su suscripción a las comunicaciones de comercialización de LUPKYNIS.</p>
                        <p className="legend">* = campo obligatorio</p>
                        <UnsubscribeForm />
                    </div>
                    <div className="hero__image">
                        <Image data={{desktopSrc: 'registration/hero.png', mobileSrc: 'registration/hero--mobile.png'}} />
                    </div>
                </div>
            </div>
        </Hero>
    </Layout>
)

export default unsubscribe